import Axios from 'axios';

export default {
    listPriceForProducts(productIds, cancelToken) {
        if (Array.isArray(productIds)) {
            productIds = productIds.join(',')
        }

        return Axios.get('/api/price/list-price?products=' + productIds, {
            cancelToken
        })
    },
    basketPricesForProducts(productIds, cancelToken) {
        if (Array.isArray(productIds)) {
            productIds = productIds.join(',')
        }

        return Axios.get('/api/price/basket-price?products=' + productIds, {
            cancelToken
        })
    },
    miniBasketPricesForProducts(productIds, cancelToken) {
        if (Array.isArray(productIds)) {
            productIds = productIds.join(',')
        }

        return Axios.get('/api/price/basket-price?minibasket=1&products=' + productIds, {
            cancelToken
        })
    }
}
